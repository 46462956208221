import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TextField, Typography, Alert, Container, CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { login } from '../redux/actions/authActions';

const LoginContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <Container>
      <LoginContainer>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
          Login
        </Typography>
        {authError && <Alert severity="error">{authError}</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            fullWidth
            required
          />
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            fullWidth
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </LoginContainer>
    </Container>
  );
};

export default LoginPage;



// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Box, Button, TextField, Typography, Alert } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { login } from '../redux/actions/authActions';
//
// const LoginContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '400px',
//   margin: 'auto',
//   marginTop: theme.spacing(8),
// }));
//
// const LoginPage = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const dispatch = useDispatch();
//   const authError = useSelector((state) => state.auth.error);
//
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(login({ email, password }));
//   };
//
//   return (
//     <LoginContainer>
//       <Typography variant="h5" gutterBottom>
//         Login
//       </Typography>
//       {authError && <Alert severity="error">{authError}</Alert>}
//       <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <TextField
//           label="Email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           type="email"
//           fullWidth
//           required
//         />
//         <TextField
//           label="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           type="password"
//           fullWidth
//           required
//         />
//         <Button type="submit" variant="contained" color="primary" fullWidth>
//           Login
//         </Button>
//       </Box>
//     </LoginContainer>
//   );
// };
//
// export default LoginPage;
