import React from 'react';
import { Box, Typography, Grid, Avatar, Container } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const AboutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const TeamMember = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const AboutPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <AboutContainer>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
          About DomainTug
        </Typography>
        <Typography variant="body1" paragraph>
          DomainTug is your trusted partner in finding the perfect domain name. Our AI-powered platform provides tailored domain name suggestions to meet your specific needs. Whether you're starting a new business, launching a product, or creating a personal blog, we help you find the ideal domain name at an affordable price.
        </Typography>
        <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1" paragraph>
          Our mission is to simplify the process of finding and purchasing domain names. We leverage advanced AI technology to deliver the best domain name suggestions, ensuring that you can focus on building your brand without worrying about domain availability.
        </Typography>
        <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
          Meet the Team
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <TeamMember>
              <Avatar alt="John Doe" src="/path/to/avatar1.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
              <Typography variant="h6">John Doe</Typography>
              <Typography variant="body2" color="textSecondary">CEO & Founder</Typography>
            </TeamMember>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TeamMember>
              <Avatar alt="Jane Smith" src="/path/to/avatar2.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
              <Typography variant="h6">Jane Smith</Typography>
              <Typography variant="body2" color="textSecondary">CTO</Typography>
            </TeamMember>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TeamMember>
              <Avatar alt="Alice Johnson" src="/path/to/avatar3.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
              <Typography variant="h6">Alice Johnson</Typography>
              <Typography variant="body2" color="textSecondary">Head of Marketing</Typography>
            </TeamMember>
          </Grid>
        </Grid>
      </AboutContainer>
    </Container>
  );
};

export default AboutPage;



// import React from 'react';
// import { Box, Typography, Grid, Avatar } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const AboutContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const TeamMember = styled(Box)(({ theme }) => ({
//   textAlign: 'center',
//   padding: theme.spacing(2),
// }));
//
// const AboutPage = () => (
//   <AboutContainer>
//     <Typography variant="h4" gutterBottom>
//       About DomainTug
//     </Typography>
//     <Typography variant="body1" paragraph>
//       DomainTug is your trusted partner in finding the perfect domain name. Our AI-powered platform provides tailored domain name suggestions to meet your specific needs. Whether you're starting a new business, launching a product, or creating a personal blog, we help you find the ideal domain name at an affordable price.
//     </Typography>
//     <Typography variant="h5" gutterBottom>
//       Our Mission
//     </Typography>
//     <Typography variant="body1" paragraph>
//       Our mission is to simplify the process of finding and purchasing domain names. We leverage advanced AI technology to deliver the best domain name suggestions, ensuring that you can focus on building your brand without worrying about domain availability.
//     </Typography>
//     <Typography variant="h5" gutterBottom>
//       Meet the Team
//     </Typography>
//     <Grid container spacing={4}>
//       <Grid item xs={12} sm={6} md={4}>
//         <TeamMember>
//           <Avatar alt="John Doe" src="/path/to/avatar1.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
//           <Typography variant="h6">John Doe</Typography>
//           <Typography variant="body2" color="textSecondary">CEO & Founder</Typography>
//         </TeamMember>
//       </Grid>
//       <Grid item xs={12} sm={6} md={4}>
//         <TeamMember>
//           <Avatar alt="Jane Smith" src="/path/to/avatar2.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
//           <Typography variant="h6">Jane Smith</Typography>
//           <Typography variant="body2" color="textSecondary">CTO</Typography>
//         </TeamMember>
//       </Grid>
//       <Grid item xs={12} sm={6} md={4}>
//         <TeamMember>
//           <Avatar alt="Alice Johnson" src="/path/to/avatar3.jpg" sx={{ width: 100, height: 100, margin: 'auto' }} />
//           <Typography variant="h6">Alice Johnson</Typography>
//           <Typography variant="body2" color="textSecondary">Head of Marketing</Typography>
//         </TeamMember>
//       </Grid>
//     </Grid>
//   </AboutContainer>
// );
//
// export default AboutPage;
