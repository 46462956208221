import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';

const HelpContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const HelpPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <HelpContainer>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
          Help & Support
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the DomainTug Help & Support page. Here you will find answers to frequently asked questions and guides on how to use our platform.
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I search for a domain?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              To search for a domain, go to the Domains page and enter your desired keywords, domain length, and top-level domain (TLD). Click the "Search" button to get AI-powered domain suggestions.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I purchase a domain?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Once you find a domain you like, click the "Buy" button next to the domain name. You will be redirected to the domain registrar's website to complete the purchase.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I save my favorite domains?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              To save your favorite domains, you need to create an account and log in. Once logged in, you can save domains to your profile for later review.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I contact support?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              If you need further assistance, please contact our support team at support@DomainTug.com. We are here to help you 24/7.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </HelpContainer>
    </Container>
  );
};

export default HelpPage;



// import React from 'react';
// import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//
// const HelpContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const HelpPage = () => (
//   <HelpContainer>
//     <Typography variant="h4" gutterBottom>
//       Help & Support
//     </Typography>
//     <Typography variant="body1" paragraph>
//       Welcome to the DomainTug Help & Support page. Here you will find answers to frequently asked questions and guides on how to use our platform.
//     </Typography>
//     <Accordion>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6">How do I search for a domain?</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography variant="body1">
//           To search for a domain, go to the Domains page and enter your desired keywords, domain length, and top-level domain (TLD). Click the "Search" button to get AI-powered domain suggestions.
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6">How do I purchase a domain?</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography variant="body1">
//           Once you find a domain you like, click the "Buy" button next to the domain name. You will be redirected to the domain registrar's website to complete the purchase.
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6">How do I save my favorite domains?</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography variant="body1">
//           To save your favorite domains, you need to create an account and log in. Once logged in, you can save domains to your profile for later review.
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6">How do I contact support?</Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography variant="body1">
//           If you need further assistance, please contact our support team at support@DomainTug.com. We are here to help you 24/7.
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </HelpContainer>
// );
//
// export default HelpPage;
