import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk'; // Corrected import
import domainReducer from './reducers/domainReducer';
import userReducer from './reducers/userReducer';
import authReducer from './reducers/authReducer';

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  domain: domainReducer,
  user: userReducer,
  auth: authReducer,
});

// Configure the Redux store with middleware and dev tools
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
