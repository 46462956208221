import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { logEvent } from '../analytics';

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '600px',
  margin: 'auto',
}));

const DomainSearch = ({ onSearch }) => {
  const [useCaseDescription, setUseCase] = useState('');
  const [minLength, setMinLength] = useState(8);
  const [maxLength, setMaxLength] = useState(12);
  const [tld, setTld] = useState([]);
  const [numberOfDomains, setNumSuggestions] = useState(5);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { useCaseDescription, minLength, maxLength, tld, numberOfDomains };
    logEvent('DomainSearch', 'submit', JSON.stringify(params));
    onSearch(params);
  };

  return (
    <FormContainer component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Find Your Perfect Domain
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Domain description here..."
            value={useCaseDescription}
            onChange={(e) => setUseCase(e.target.value)}
            fullWidth
            required
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Min Length"
            value={minLength}
            onChange={(e) => setMinLength(e.target.value)}
            type="number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Max Length"
            value={maxLength}
            onChange={(e) => setMaxLength(e.target.value)}
            type="number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Top-Level Domain (TLD)"
            value={tld}
            onChange={(e) => setTld(e.target.value)}
            select
            fullWidth
            required
          >
            <MenuItem value=".com">.com</MenuItem>
            <MenuItem value=".net">.net</MenuItem>
            <MenuItem value=".org">.org</MenuItem>
            <MenuItem value=".io">.io</MenuItem>
            <MenuItem value=".ai">.ai</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Number of Suggestions"
            value={numberOfDomains}
            onChange={(e) => setNumSuggestions(e.target.value)}
            type="number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default DomainSearch;




// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Grid, MenuItem } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const FormContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '600px',
//   margin: 'auto',
// }));
//
// const DomainSearch = () => {
//   const [useCase, setUseCase] = useState('');
//   const [minLength, setMinLength] = useState('');
//   const [maxLength, setMaxLength] = useState('');
//   const [tld, setTld] = useState('');
//   const [numSuggestions, setNumSuggestions] = useState('');
//
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//   };
//
//   return (
//     <FormContainer component="form" onSubmit={handleSubmit}>
//       <Typography variant="h5" gutterBottom>
//         Find Your Perfect Domain
//       </Typography>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <TextField
//             label="Use Case"
//             value={useCase}
//             onChange={(e) => setUseCase(e.target.value)}
//             fullWidth
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Min Length"
//             value={minLength}
//             onChange={(e) => setMinLength(e.target.value)}
//             type="number"
//             fullWidth
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Max Length"
//             value={maxLength}
//             onChange={(e) => setMaxLength(e.target.value)}
//             type="number"
//             fullWidth
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="Top-Level Domain (TLD)"
//             value={tld}
//             onChange={(e) => setTld(e.target.value)}
//             select
//             fullWidth
//             required
//           >
//             <MenuItem value=".com">.com</MenuItem>
//             <MenuItem value=".net">.net</MenuItem>
//             <MenuItem value=".org">.org</MenuItem>
//             <MenuItem value=".io">.io</MenuItem>
//             <MenuItem value=".ai">.ai</MenuItem>
//           </TextField>
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="Number of Suggestions"
//             value={numSuggestions}
//             onChange={(e) => setNumSuggestions(e.target.value)}
//             type="number"
//             fullWidth
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <Button type="submit" variant="contained" color="primary" fullWidth>
//             Search
//           </Button>
//         </Grid>
//       </Grid>
//     </FormContainer>
//   );
// };
//
// export default DomainSearch;
