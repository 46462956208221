import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Avatar, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchUserProfileThunk } from '../redux/actions/userActions';

const ProfileContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '800px',
  margin: 'auto',
  marginTop: theme.spacing(4),
}));

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserProfileThunk());
  }, [dispatch]);

  return (
    <ProfileContainer>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      ) : (
        user && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar alt={user.name} src={user.avatarUrl} sx={{ width: 80, height: 80 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5">{user.name}</Typography>
                <Typography variant="body1" color="textSecondary">{user.email}</Typography>
              </Grid>
            </Grid>
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
              <Typography variant="body2">Username: {user.username}</Typography>
              <Typography variant="body2">Joined: {new Date(user.joinedDate).toLocaleDateString()}</Typography>
            </Box>
            <Box mt={4} textAlign="center">
              <Button variant="contained" color="primary">Edit Profile</Button>
            </Box>
          </>
        )
      )}
    </ProfileContainer>
  );
};

export default ProfilePage;



// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Box, Typography, Grid, Avatar, Button, CircularProgress } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { fetchUserProfileThunk } from '../redux/actions/userActions';
// import UserProfile from '../components/UserProfile';
//
// const ProfileContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const ProfilePage = () => {
//   const dispatch = useDispatch();
//   const { user, loading, error } = useSelector((state) => state.user);
//
//   useEffect(() => {
//     dispatch(fetchUserProfileThunk());
//   }, [dispatch]);
//
//   return (
//     <ProfileContainer>
//       {loading ? (
//         <Box display="flex" justifyContent="center" alignItems="center" height="100%">
//           <CircularProgress />
//         </Box>
//       ) : error ? (
//         <Typography variant="h6" color="error">
//           {error}
//         </Typography>
//       ) : (
//         <>
//           <Grid container spacing={2} alignItems="center">
//             <Grid item>
//               <Avatar alt={user.name} src={user.avatarUrl} sx={{ width: 80, height: 80 }} />
//             </Grid>
//             <Grid item xs>
//               <Typography variant="h5">{user.name}</Typography>
//               <Typography variant="body1" color="textSecondary">{user.email}</Typography>
//             </Grid>
//           </Grid>
//           <Box mt={4}>
//             <Typography variant="h6" gutterBottom>
//               Account Details
//             </Typography>
//             <Typography variant="body2">Username: {user.username}</Typography>
//             <Typography variant="body2">Joined: {new Date(user.joinedDate).toLocaleDateString()}</Typography>
//           </Box>
//           <Box mt={4} textAlign="center">
//             <Button variant="contained" color="primary">Edit Profile</Button>
//           </Box>
//         </>
//       )}
//     </ProfileContainer>
//   );
// };
//
// export default ProfilePage;
//
