import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import store from './redux/store';
import theme from './theme';
import './index.css';
import { initGA, logPageView } from './analytics';
import { initSentry } from './sentry';

initGA();
initSentry();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Log page views on route changes
window.addEventListener('popstate', logPageView);
//
//
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { CssBaseline, ThemeProvider } from '@mui/material';
// import { ProSidebarProvider } from 'react-pro-sidebar';
// import App from './App';
// import store from './redux/store';
// import theme from './theme';
// import './index.css';
//
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Router>
//           <ProSidebarProvider>
//             <App />
//           </ProSidebarProvider>
//         </Router>
//       </ThemeProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );



// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { CssBaseline, ThemeProvider } from '@mui/material';
// import App from './App';
// import store from './redux/store';
// import theme from './theme';
// import './index.css';
//
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Router>
//           <App />
//         </Router>
//       </ThemeProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
