import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import DomainSearch from '../components/DomainSearch';
import DomainResults from '../components/DomainResults';
import { searchForDomains } from '../redux/actions/domainActions';

const SearchContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '800px',
  margin: 'auto',
  marginTop: theme.spacing(4),
}));

const DomainSearchPage = () => {
  const dispatch = useDispatch();
  const { results, loading, error } = useSelector((state) => state.domain);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useState(null);

  const handleSearch = (params) => {
    setSearchParams(params);
    dispatch(searchForDomains(params));
  };

  return (
    <SearchContainer>
      {/*<Typography variant="h4" gutterBottom>*/}
      <Typography variant="h4" gutterBottom align="center" sx={{ bgcolor: '#3a3a3a', color: 'white', padding: 1, borderRadius: 1 }}>

        Domain Search
      </Typography>
      <DomainSearch onSearch={handleSearch} />
      {loading && <CircularProgress sx={{ mt: 2 }} />}
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {results && results.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <DomainResults results={results} />
        </Box>
      )}
    </SearchContainer>
  );
};

export default DomainSearchPage;



// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Box, Typography, CircularProgress, Alert } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import DomainSearch from '../components/DomainSearch';
// import DomainResults from '../components/DomainResults';
// import { searchForDomains } from '../redux/actions/domainActions';
//
// const SearchContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const DomainSearchPage = () => {
//   const dispatch = useDispatch();
//   const { results, loading, error } = useSelector((state) => state.domain);
//   const [searchParams, setSearchParams] = useState(null);
//
//   const handleSearch = (params) => {
//     setSearchParams(params);
//     dispatch(searchForDomains(params));
//   };
//
//   return (
//     <SearchContainer>
//       <Typography variant="h4" gutterBottom>
//         Domain Search
//       </Typography>
//       <DomainSearch onSearch={handleSearch} />
//       {loading && <CircularProgress sx={{ mt: 2 }} />}
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {results && results.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <DomainResults results={results} />
//         </Box>
//       )}
//     </SearchContainer>
//   );
// };
//
// export default DomainSearchPage;
