import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(8, 2),
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
  },
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  textAlign: 'center',
}));

const FeatureItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

const SuperscriptMessage = styled('sup')(({ theme }) => ({
  fontSize: '0.6rem',
  background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  animation: 'shine 2s linear infinite',
  '@keyframes shine': {
    '0%': { backgroundPosition: '200% center' },
    '100%': { backgroundPosition: '-200% center' },
  },
  backgroundSize: '200% auto',
  marginLeft: theme.spacing(0.5),
}));

const AnimatedButton = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(1.5, 3),
  fontSize: '1.25rem',
  color: theme.palette.common.white,
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'none',
  transition: 'background 0.5s, transform 0.2s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    transform: 'scale(1.05)',
  },
}));

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <HeroSection>
        <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
          Welcome to DomainTug
        </Typography>
        <Typography variant={isMobile ? "body1" : "h6"} gutterBottom>
          Your trusted partner in finding the perfect domain name.
        </Typography>
        <AnimatedButton to="/domains">
          Get Started
        </AnimatedButton>
      </HeroSection>
      <FeatureSection>
        <Typography variant="h4" gutterBottom>
          Why Choose DomainTug?
        </Typography>
        <Grid container spacing={4}>
          <FeatureItem item xs={12} md={4}>
            <Typography variant="h6">AI-Powered Suggestions</Typography>
            <Typography variant="body1">
              Leverage the power of AI to get the best domain name suggestions tailored to your needs.
            </Typography>
          </FeatureItem>
          <FeatureItem item xs={12} md={4}>
            <Typography variant="h6">
              Affordable Prices<SuperscriptMessage>Come Soon</SuperscriptMessage>
            </Typography>
            <Typography variant="body1">
              Find domain names available for purchase below $5.
            </Typography>
          </FeatureItem>
          <FeatureItem item xs={12} md={4}>
            <Typography variant="h6">
              Easy Integration<SuperscriptMessage>Come Soon</SuperscriptMessage>
            </Typography>
            <Typography variant="body1">
              Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
            </Typography>
          </FeatureItem>
        </Grid>
      </FeatureSection>
    </Container>
  );
};

export default HomePage;




// import React from 'react';
// import { Box, Typography, Button, Grid, Container } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
// import { useMediaQuery } from '@mui/material';
//
// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   padding: theme.spacing(8, 2),
//   textAlign: 'center',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   marginBottom: theme.spacing(4),
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(4, 2),
//   },
// }));
//
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   textAlign: 'center',
// }));
//
// const FeatureItem = styled(Grid)(({ theme }) => ({
//   padding: theme.spacing(2),
//   position: 'relative',
//   [theme.breakpoints.down('sm')]: {
//     textAlign: 'left',
//   },
// }));
//
// const SuperscriptMessage = styled('sup')(({ theme }) => ({
//   fontSize: '0.7rem',
//   background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
//   backgroundClip: 'text',
//   WebkitBackgroundClip: 'text',
//   color: 'transparent',
//   animation: 'shine 2s linear infinite',
//   '@keyframes shine': {
//     '0%': { backgroundPosition: '200% center' },
//     '100%': { backgroundPosition: '-200% center' },
//   },
//   backgroundSize: '200% auto',
//   marginLeft: theme.spacing(0.5),
// }));
//
// const HomePage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//   return (
//     <Container>
//       <HeroSection>
//         <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
//           Welcome to DomainTug
//         </Typography>
//         <Typography variant={isMobile ? "body1" : "h6"} gutterBottom>
//           Your trusted partner in finding the perfect domain name.
//         </Typography>
//         <Button
//           component={Link}
//           to="/domains"
//           variant="contained"
//           color="secondary"
//           size="large"
//           sx={{ mt: 3 }}
//         >
//           Get Started
//         </Button>
//       </HeroSection>
//       <FeatureSection>
//         <Typography variant="h4" gutterBottom>
//           Why Choose DomainTug?
//         </Typography>
//         <Grid container spacing={4}>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">AI-Powered Suggestions</Typography>
//             <Typography variant="body1">
//               Leverage the power of AI to get the best domain name suggestions tailored to your needs.
//             </Typography>
//           </FeatureItem>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">
//               Affordable Prices<SuperscriptMessage>Come Soon</SuperscriptMessage>
//             </Typography>
//             <Typography variant="body1">
//               Find domain names available for purchase below $5.
//             </Typography>
//           </FeatureItem>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">
//               Easy Integration<SuperscriptMessage>Come Soon</SuperscriptMessage>
//             </Typography>
//             <Typography variant="body1">
//               Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
//             </Typography>
//           </FeatureItem>
//         </Grid>
//       </FeatureSection>
//     </Container>
//   );
// };
//
// export default HomePage;







// import React from 'react';
// import { Box, Typography, Button, Grid, Container } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
// import { useMediaQuery } from '@mui/material';
//
// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   padding: theme.spacing(8, 2),
//   textAlign: 'center',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   marginBottom: theme.spacing(4),
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(4, 2),
//   },
// }));
//
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   textAlign: 'center',
// }));
//
// const FeatureItem = styled(Grid)(({ theme }) => ({
//   padding: theme.spacing(2),
//   [theme.breakpoints.down('sm')]: {
//     textAlign: 'left',
//   },
// }));
//
// const HomePage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//   return (
//     <Container>
//       <HeroSection>
//         <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
//           Welcome to DomainTug
//         </Typography>
//         <Typography variant={isMobile ? "body1" : "h6"} gutterBottom>
//           Your trusted partner in finding the perfect domain name.
//         </Typography>
//         <Button
//           component={Link}
//           to="/domains"
//           variant="contained"
//           color="secondary"
//           size="large"
//           sx={{ mt: 3 }}
//         >
//           Get Started
//         </Button>
//       </HeroSection>
//       <FeatureSection>
//         <Typography variant="h4" gutterBottom>
//           Why Choose DomainTug?
//         </Typography>
//         <Grid container spacing={4}>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">AI-Powered Suggestions</Typography>
//             <Typography variant="body1">
//               Leverage the power of AI to get the best domain name suggestions tailored to your needs.
//             </Typography>
//           </FeatureItem>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">Affordable Prices</Typography>
//             <Typography variant="body1">
//               Find domain names available for purchase below $50.
//             </Typography>
//           </FeatureItem>
//           <FeatureItem item xs={12} md={4}>
//             <Typography variant="h6">Easy Integration</Typography>
//             <Typography variant="body1">
//               Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
//             </Typography>
//           </FeatureItem>
//         </Grid>
//       </FeatureSection>
//     </Container>
//   );
// };
//
// export default HomePage;



// import React from 'react';
// import { Box, Typography, Button, Grid, Container } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
// import { useMediaQuery } from '@mui/material';
//
// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   padding: theme.spacing(8, 2),
//   textAlign: 'center',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   marginBottom: theme.spacing(4),
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(4, 2),
//   },
// }));
//
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   textAlign: 'center',
// }));
//
// const HomePage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//   return (
//     <Container>
//       <HeroSection>
//         <Typography variant="h3" gutterBottom>
//           Welcome to DomainTug
//         </Typography>
//         <Typography variant="h6" gutterBottom>
//           Your trusted partner in finding the perfect domain name.
//         </Typography>
//         <Button
//           component={Link}
//           to="/domains"
//           variant="contained"
//           color="secondary"
//           size="large"
//           sx={{ mt: 3 }}
//         >
//           Get Started
//         </Button>
//       </HeroSection>
//       <FeatureSection>
//         <Typography variant="h4" gutterBottom>
//           Why Choose DomainTug?
//         </Typography>
//         <Grid container spacing={4}>
//           <Grid item xs={12} md={4}>
//             <Typography variant="h6">AI-Powered Suggestions</Typography>
//             <Typography variant="body1">
//               Leverage the power of AI to get the best domain name suggestions tailored to your needs.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <Typography variant="h6">Affordable Prices</Typography>
//             <Typography variant="body1">
//               Find domain names available for purchase below $50.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <Typography variant="h6">Easy Integration</Typography>
//             <Typography variant="body1">
//               Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
//             </Typography>
//           </Grid>
//         </Grid>
//       </FeatureSection>
//     </Container>
//   );
// };
//
// export default HomePage;





// import React from 'react';
// import { Box, Typography, Button, Grid, Container } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
//
// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   padding: theme.spacing(8, 2),
//   textAlign: 'center',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   marginBottom: theme.spacing(4),
//   [theme.breakpoints.down('sm')]: {
//     padding: theme.spacing(4, 2),
//   },
// }));
//
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   textAlign: 'center',
// }));
//
// const HomePage = () => (
//   <Container>
//     <HeroSection>
//       <Typography variant="h3" gutterBottom>
//         Welcome to DomainTug
//       </Typography>
//       <Typography variant="h6" gutterBottom>
//         Your trusted partner in finding the perfect domain name.
//       </Typography>
//       <Button
//         component={Link}
//         to="/domains"
//         variant="contained"
//         color="secondary"
//         size="large"
//         sx={{ mt: 3 }}
//       >
//         Get Started
//       </Button>
//     </HeroSection>
//     <FeatureSection>
//       <Typography variant="h4" gutterBottom>
//         Why Choose DomainTug?
//       </Typography>
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">AI-Powered Suggestions</Typography>
//           <Typography variant="body1">
//             Leverage the power of AI to get the best domain name suggestions tailored to your needs.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">Affordable Prices</Typography>
//           <Typography variant="body1">
//             Find domain names available for purchase below $50.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">Easy Integration</Typography>
//           <Typography variant="body1">
//             Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
//           </Typography>
//         </Grid>
//       </Grid>
//     </FeatureSection>
//   </Container>
// );
//
// export default HomePage;



// import React from 'react';
// import { Box, Typography, Button, Grid } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
//
// const HeroSection = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   padding: theme.spacing(8, 2),
//   textAlign: 'center',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   marginBottom: theme.spacing(4),
// }));
//
// const FeatureSection = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   textAlign: 'center',
// }));
//
// const HomePage = () => (
//   <Box sx={{ flexGrow: 1, p: 3 }}>
//     <HeroSection>
//       <Typography variant="h3" gutterBottom>
//         Welcome to DomainTug
//       </Typography>
//       <Typography variant="h6" gutterBottom>
//         Your trusted partner in finding the perfect domain name.
//       </Typography>
//       <Button
//         component={Link}
//         to="/domains"
//         variant="contained"
//         color="secondary"
//         size="large"
//         sx={{ mt: 3 }}
//       >
//         Get Started
//       </Button>
//     </HeroSection>
//     <FeatureSection>
//       <Typography variant="h4" gutterBottom>
//         Why Choose DomainTug?
//       </Typography>
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">AI-Powered Suggestions</Typography>
//           <Typography variant="body1">
//             Leverage the power of AI to get the best domain name suggestions tailored to your needs.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">Affordable Prices</Typography>
//           <Typography variant="body1">
//             Find domain names available for purchase below $50.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h6">Easy Integration</Typography>
//           <Typography variant="body1">
//             Seamlessly integrate with popular domain registrars like Namecheap and GoDaddy.
//           </Typography>
//         </Grid>
//       </Grid>
//     </FeatureSection>
//   </Box>
// );
//
// export default HomePage;
