// import axios from 'axios';
//
// // Action Types
// export const SET_DOMAIN_RESULTS = 'SET_DOMAIN_RESULTS';
// export const DOMAIN_SEARCH_REQUEST = 'DOMAIN_SEARCH_REQUEST';
// export const DOMAIN_SEARCH_SUCCESS = 'DOMAIN_SEARCH_SUCCESS';
// export const DOMAIN_SEARCH_FAILURE = 'DOMAIN_SEARCH_FAILURE';
// export const DOMAIN_CHECK_REQUEST = 'DOMAIN_CHECK_REQUEST';
// export const DOMAIN_CHECK_SUCCESS = 'DOMAIN_CHECK_SUCCESS';
// export const DOMAIN_CHECK_FAILURE = 'DOMAIN_CHECK_FAILURE';
//
// // Action Creators
//
// // Set Domain Results
// export const setDomainResults = (results) => ({
//   type: SET_DOMAIN_RESULTS,
//   payload: results,
// });
//
// // Domain Search Request
// export const domainSearchRequest = () => ({
//   type: DOMAIN_SEARCH_REQUEST,
// });
//
// // Domain Search Success
// export const domainSearchSuccess = (results) => ({
//   type: DOMAIN_SEARCH_SUCCESS,
//   payload: results,
// });
//
// // Domain Search Failure
// export const domainSearchFailure = (error) => ({
//   type: DOMAIN_SEARCH_FAILURE,
//   payload: error,
// });
//
// // Domain Check Request
// export const domainCheckRequest = () => ({
//   type: DOMAIN_CHECK_REQUEST,
// });
//
// // Domain Check Success
// export const domainCheckSuccess = (result) => ({
//   type: DOMAIN_CHECK_SUCCESS,
//   payload: result,
// });
//
// // Domain Check Failure
// export const domainCheckFailure = (error) => ({
//   type: DOMAIN_CHECK_FAILURE,
//   payload: error,
// });
//
// // Thunk Actions
//
// // Search Domains Thunk
// export const searchForDomains = (params) => async (dispatch) => {
//   dispatch(domainSearchRequest());
//   try {
//     const response = await axios.post('/api/v1/domains/', params);
//     dispatch(domainSearchSuccess(response.data));
//   } catch (error) {
//     dispatch(domainSearchFailure(error.message));
//   }
// };
//
// // Check Domain Availability Thunk
// export const checkDomainAvailability = (domainName) => async (dispatch) => {
//   dispatch(domainCheckRequest());
//   try {
//     const response = await axios.get(`/api/v1/domains/${domainName}`);
//     dispatch(domainCheckSuccess(response.data));
//   } catch (error) {
//     dispatch(domainCheckFailure(error.message));
//   }
// };
//



import { searchDomains } from '../../services/apiService';

// Action Types
export const SET_DOMAIN_RESULTS = 'SET_DOMAIN_RESULTS';
export const DOMAIN_SEARCH_REQUEST = 'DOMAIN_SEARCH_REQUEST';
export const DOMAIN_SEARCH_SUCCESS = 'DOMAIN_SEARCH_SUCCESS';
export const DOMAIN_SEARCH_FAILURE = 'DOMAIN_SEARCH_FAILURE';

// Action Creators

// Set Domain Results
export const setDomainResults = (results) => ({
  type: SET_DOMAIN_RESULTS,
  payload: results,
});

// Domain Search Request
export const domainSearchRequest = () => ({
  type: DOMAIN_SEARCH_REQUEST,
});

// Domain Search Success
export const domainSearchSuccess = (results) => ({
  type: DOMAIN_SEARCH_SUCCESS,
  payload: results,
});

// Domain Search Failure
export const domainSearchFailure = (error) => ({
  type: DOMAIN_SEARCH_FAILURE,
  payload: error,
});

// Thunk Actions

// Search Domains Thunk
export const searchForDomains = (params) => async (dispatch) => {
  dispatch(domainSearchRequest());
  try {
    const results = await searchDomains(params);
    dispatch(domainSearchSuccess(results));
  } catch (error) {
    dispatch(domainSearchFailure(error.message));
  }
};
