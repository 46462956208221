import { fetchUserProfile, updateUserProfile } from '../../services/apiService';

// Action Types
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

// Action Creators

// Fetch User Profile Actions
export const fetchUserProfileRequest = () => ({
  type: FETCH_USER_PROFILE_REQUEST,
});

export const fetchUserProfileSuccess = (user) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: user,
});

export const fetchUserProfileFailure = (error) => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: error,
});

// Update User Profile Actions
export const updateUserProfileRequest = () => ({
  type: UPDATE_USER_PROFILE_REQUEST,
});

export const updateUserProfileSuccess = (user) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: user,
});

export const updateUserProfileFailure = (error) => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload: error,
});

// Thunk Actions

// Fetch User Profile Thunk
export const fetchUserProfileThunk = () => async (dispatch) => {
  dispatch(fetchUserProfileRequest());
  try {
    const user = await fetchUserProfile();
    dispatch(fetchUserProfileSuccess(user));
  } catch (error) {
    dispatch(fetchUserProfileFailure(error.message));
  }
};

// Update User Profile Thunk
export const updateUserProfileThunk = (profileData) => async (dispatch) => {
  dispatch(updateUserProfileRequest());
  try {
    const user = await updateUserProfile(profileData);
    dispatch(updateUserProfileSuccess(user));
  } catch (error) {
    dispatch(updateUserProfileFailure(error.message));
  }
};




// import { getUserProfile, updateProfile } from '../../services/apiService';
//
// // Action Types
// export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
// export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
// export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
//
// export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
// export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
// export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
//
// // Action Creators
//
// // Fetch User Profile Actions
// export const fetchUserProfileRequest = () => ({
//   type: FETCH_USER_PROFILE_REQUEST,
// });
//
// export const fetchUserProfileSuccess = (user) => ({
//   type: FETCH_USER_PROFILE_SUCCESS,
//   payload: user,
// });
//
// export const fetchUserProfileFailure = (error) => ({
//   type: FETCH_USER_PROFILE_FAILURE,
//   payload: error,
// });
//
// // Update User Profile Actions
// export const updateUserProfileRequest = () => ({
//   type: UPDATE_USER_PROFILE_REQUEST,
// });
//
// export const updateUserProfileSuccess = (user) => ({
//   type: UPDATE_USER_PROFILE_SUCCESS,
//   payload: user,
// });
//
// export const updateUserProfileFailure = (error) => ({
//   type: UPDATE_USER_PROFILE_FAILURE,
//   payload: error,
// });
//
// // Thunk Actions
//
// // Fetch User Profile Thunk
// export const fetchUserProfile = () => async (dispatch) => {
//   dispatch(fetchUserProfileRequest());
//   try {
//     const user = await getUserProfile();
//     dispatch(fetchUserProfileSuccess(user));
//   } catch (error) {
//     dispatch(fetchUserProfileFailure(error.message));
//   }
// };
//
// // Update User Profile Thunk
// export const updateUserProfile = (profileData) => async (dispatch) => {
//   dispatch(updateUserProfileRequest());
//   try {
//     const user = await updateProfile(profileData);
//     dispatch(updateUserProfileSuccess(user));
//   } catch (error) {
//     dispatch(updateUserProfileFailure(error.message));
//   }
// };



// import { getUserProfile, updateUserProfile } from '../../services/apiService';
//
// // Action Types
// export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
// export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
// export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
//
// export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
// export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
// export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
//
// // Action Creators
//
// // Fetch User Profile Actions
// export const fetchUserProfileRequest = () => ({
//   type: FETCH_USER_PROFILE_REQUEST,
// });
//
// export const fetchUserProfileSuccess = (user) => ({
//   type: FETCH_USER_PROFILE_SUCCESS,
//   payload: user,
// });
//
// export const fetchUserProfileFailure = (error) => ({
//   type: FETCH_USER_PROFILE_FAILURE,
//   payload: error,
// });
//
// // Update User Profile Actions
// export const updateUserProfileRequest = () => ({
//   type: UPDATE_USER_PROFILE_REQUEST,
// });
//
// export const updateUserProfileSuccess = (user) => ({
//   type: UPDATE_USER_PROFILE_SUCCESS,
//   payload: user,
// });
//
// export const updateUserProfileFailure = (error) => ({
//   type: UPDATE_USER_PROFILE_FAILURE,
//   payload: error,
// });
//
// // Thunk Actions
//
// // Fetch User Profile Thunk
// export const fetchUserProfile = () => async (dispatch) => {
//   dispatch(fetchUserProfileRequest());
//   try {
//     const user = await getUserProfile();
//     dispatch(fetchUserProfileSuccess(user));
//   } catch (error) {
//     dispatch(fetchUserProfileFailure(error.message));
//   }
// };
//
// // Update User Profile Thunk
// export const updateUserProfile = (profileData) => async (dispatch) => {
//   dispatch(updateUserProfileRequest());
//   try {
//     const user = await updateUserProfile(profileData);
//     dispatch(updateUserProfileSuccess(user));
//   } catch (error) {
//     dispatch(updateUserProfileFailure(error.message));
//   }
// };
//
//
//
//
//
// // import { fetchUserProfile, updateUserProfile } from '../../services/apiService';
// //
// // // Action Types
// // export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
// // export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
// // export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
// //
// // export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
// // export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
// // export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
// //
// // // Action Creators
// //
// // // Fetch User Profile Actions
// // export const fetchUserProfileRequest = () => ({
// //   type: FETCH_USER_PROFILE_REQUEST,
// // });
// //
// // export const fetchUserProfileSuccess = (user) => ({
// //   type: FETCH_USER_PROFILE_SUCCESS,
// //   payload: user,
// // });
// //
// // export const fetchUserProfileFailure = (error) => ({
// //   type: FETCH_USER_PROFILE_FAILURE,
// //   payload: error,
// // });
// //
// // // Update User Profile Actions
// // export const updateUserProfileRequest = () => ({
// //   type: UPDATE_USER_PROFILE_REQUEST,
// // });
// //
// // export const updateUserProfileSuccess = (user) => ({
// //   type: UPDATE_USER_PROFILE_SUCCESS,
// //   payload: user,
// // });
// //
// // export const updateUserProfileFailure = (error) => ({
// //   type: UPDATE_USER_PROFILE_FAILURE,
// //   payload: error,
// // });
// //
// // // Thunk Actions
// //
// // // Fetch User Profile Thunk
// // export const fetchUserProfile = () => async (dispatch) => {
// //   dispatch(fetchUserProfileRequest());
// //   try {
// //     const user = await fetchUserProfile();
// //     dispatch(fetchUserProfileSuccess(user));
// //   } catch (error) {
// //     dispatch(fetchUserProfileFailure(error.message));
// //   }
// // };
// //
// // // Update User Profile Thunk
// // export const updateUserProfile = (profileData) => async (dispatch) => {
// //   dispatch(updateUserProfileRequest());
// //   try {
// //     const user = await updateUserProfile(profileData);
// //     dispatch(updateUserProfileSuccess(user));
// //   } catch (error) {
// //     dispatch(updateUserProfileFailure(error.message));
// //   }
// // };
