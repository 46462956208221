import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, useMediaQuery, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DomainIcon from '@mui/icons-material/Domain';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const drawerContent = (
    <Box sx={{ width: 250 }}>
      <List>
        <ListItem button component="a" href="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component="a" href="/domains">
          <ListItemIcon>
            <DomainIcon />
          </ListItemIcon>
          <ListItemText primary="Domains" />
        </ListItem>
        <ListItem button component="a" href="/about">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button component="a" href="/contact">
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component="a" href="/help">
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </ListItem>
        <ListItem button component="a" href="/login">
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: scrolled ? 'primary.dark' : 'primary.main', boxShadow: 3, transition: 'background-color 0.3s' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{ flexGrow: 1, textDecoration: 'none', color: scrolled ? 'secondary.main' : 'inherit', transition: 'color 0.3s' }}
          >
            DomainTug
          </Typography>
          {!isMobile && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <IconButton color="inherit">
                  <AccountCircle />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;





// Working well as of 30/10/2024

// import React, { useState, useEffect } from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box, useMediaQuery, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import DomainIcon from '@mui/icons-material/Domain';
// import InfoIcon from '@mui/icons-material/Info';
// import ContactMailIcon from '@mui/icons-material/ContactMail';
// import HelpIcon from '@mui/icons-material/Help';
// import LoginIcon from '@mui/icons-material/Login';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [scrolled, setScrolled] = useState(false);
//
//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const drawerContent = (
//     <Box sx={{ width: 250 }}>
//       <List>
//         <ListItem button component="a" href="/">
//           <ListItemIcon>
//             <HomeIcon />
//           </ListItemIcon>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component="a" href="/domains">
//           <ListItemIcon>
//             <DomainIcon />
//           </ListItemIcon>
//           <ListItemText primary="Domains" />
//         </ListItem>
//         <ListItem button component="a" href="/about">
//           <ListItemIcon>
//             <InfoIcon />
//           </ListItemIcon>
//           <ListItemText primary="About Us" />
//         </ListItem>
//         <ListItem button component="a" href="/contact">
//           <ListItemIcon>
//             <ContactMailIcon />
//           </ListItemIcon>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component="a" href="/help">
//           <ListItemIcon>
//             <HelpIcon />
//           </ListItemIcon>
//           <ListItemText primary="Help" />
//         </ListItem>
//         <ListItem button component="a" href="/login">
//           <ListItemIcon>
//             <LoginIcon />
//           </ListItemIcon>
//           <ListItemText primary="Login" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar position="sticky" sx={{ bgcolor: scrolled ? 'primary.dark' : 'primary.main', boxShadow: 3, transition: 'background-color 0.3s' }}>
//         <Toolbar>
//           <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle}>
//             <MenuIcon />
//           </IconButton>
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{ flexGrow: 1, textDecoration: 'none', color: scrolled ? 'secondary.main' : 'inherit', transition: 'color 0.3s' }}
//           >
//             DomainTug
//           </Typography>
//           {!isMobile && (
//             <>
//               <Search>
//                 <SearchIconWrapper>
//                   <SearchIcon />
//                 </SearchIconWrapper>
//                 <StyledInputBase
//                   placeholder="Search…"
//                   inputProps={{ 'aria-label': 'search' }}
//                 />
//               </Search>
//               <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//                 <IconButton color="inherit">
//                   <AccountCircle />
//                 </IconButton>
//               </Box>
//             </>
//           )}
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="left"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//       >
//         {drawerContent}
//       </Drawer>
//     </>
//   );
// };
//
// export default Header;





// Working well

// import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box, Badge, useMediaQuery, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// // import NotificationsIcon from '@mui/icons-material/Notifications';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import DomainIcon from '@mui/icons-material/Domain';
// import InfoIcon from '@mui/icons-material/Info';
// import ContactMailIcon from '@mui/icons-material/ContactMail';
// import HelpIcon from '@mui/icons-material/Help';
// import LoginIcon from '@mui/icons-material/Login';
// import { useState } from 'react';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [mobileOpen, setMobileOpen] = useState(false);
//
//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };
//
//   const drawerContent = (
//     <Box sx={{ width: 250 }}>
//       <List>
//         <ListItem button component="a" href="/">
//           <ListItemIcon>
//             <HomeIcon />
//           </ListItemIcon>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component="a" href="/domains">
//           <ListItemIcon>
//             <DomainIcon />
//           </ListItemIcon>
//           <ListItemText primary="Domains" />
//         </ListItem>
//         <ListItem button component="a" href="/about">
//           <ListItemIcon>
//             <InfoIcon />
//           </ListItemIcon>
//           <ListItemText primary="About Us" />
//         </ListItem>
//         <ListItem button component="a" href="/contact">
//           <ListItemIcon>
//             <ContactMailIcon />
//           </ListItemIcon>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component="a" href="/help">
//           <ListItemIcon>
//             <HelpIcon />
//           </ListItemIcon>
//           <ListItemText primary="Help" />
//         </ListItem>
//         <ListItem button component="a" href="/login">
//           <ListItemIcon>
//             <LoginIcon />
//           </ListItemIcon>
//           <ListItemText primary="Login" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
//         <Toolbar>
//           <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle}>
//             <MenuIcon />
//           </IconButton>
//           {/*<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>*/}
//           {/*  DomainTug*/}
//           {/*</Typography>*/}
//           <Typography
//               variant="h6"
//               noWrap
//               component="a"
//               href="/"
//               sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
//               >
//               DomainTug
//           </Typography>
//           {!isMobile && (
//             <Search>
//               <SearchIconWrapper>
//                 <SearchIcon />
//               </SearchIconWrapper>
//               <StyledInputBase
//                 placeholder="Search…"
//                 inputProps={{ 'aria-label': 'search' }}
//               />
//             </Search>
//           )}
//           <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//             <IconButton color="inherit">
//               {/*<Badge badgeContent={4} color="secondary">*/}
//                 {/*<NotificationsIcon />*/}
//               {/*</Badge>*/}
//             </IconButton>
//             <IconButton color="inherit">
//               <AccountCircle />
//             </IconButton>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="left"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//       >
//         {drawerContent}
//       </Drawer>
//     </>
//   );
// };
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box, Badge, useMediaQuery, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import DomainIcon from '@mui/icons-material/Domain';
// import InfoIcon from '@mui/icons-material/Info';
// import ContactMailIcon from '@mui/icons-material/ContactMail';
// import { useState } from 'react';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [mobileOpen, setMobileOpen] = useState(false);
//
//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };
//
//   const drawerContent = (
//     <Box sx={{ width: 250 }}>
//       <List>
//         <ListItem button component="a" href="/">
//           <ListItemIcon>
//             <HomeIcon />
//           </ListItemIcon>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component="a" href="/domains">
//           <ListItemIcon>
//             <DomainIcon />
//           </ListItemIcon>
//           <ListItemText primary="Domains" />
//         </ListItem>
//         <ListItem button component="a" href="/about">
//           <ListItemIcon>
//             <InfoIcon />
//           </ListItemIcon>
//           <ListItemText primary="About Us" />
//         </ListItem>
//         <ListItem button component="a" href="/contact">
//           <ListItemIcon>
//             <ContactMailIcon />
//           </ListItemIcon>
//           <ListItemText primary="Contact" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
//         <Toolbar>
//           <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle}>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
//             DomainTug
//           </Typography>
//           {!isMobile && (
//             <Search>
//               <SearchIconWrapper>
//                 <SearchIcon />
//               </SearchIconWrapper>
//               <StyledInputBase
//                 placeholder="Search…"
//                 inputProps={{ 'aria-label': 'search' }}
//               />
//             </Search>
//           )}
//           <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//             <IconButton color="inherit">
//               <Badge badgeContent={4} color="secondary">
//                 <NotificationsIcon />
//               </Badge>
//             </IconButton>
//             <IconButton color="inherit">
//               <AccountCircle />
//             </IconButton>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         anchor="left"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//       >
//         {drawerContent}
//       </Drawer>
//     </>
//   );
// };
//
// export default Header;




// import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box, Badge, useMediaQuery } from '@mui/material';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MenuIcon from '@mui/icons-material/Menu';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//   return (
//     <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
//       <Toolbar>
//         <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
//           <MenuIcon />
//         </IconButton>
//         <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
//           DomainTug
//         </Typography>
//         {!isMobile && (
//           <Search>
//             <SearchIconWrapper>
//               <SearchIcon />
//             </SearchIconWrapper>
//             <StyledInputBase
//               placeholder="Search…"
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </Search>
//         )}
//         <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//           <IconButton color="inherit">
//             <Badge badgeContent={4} color="secondary">
//               <NotificationsIcon />
//             </Badge>
//           </IconButton>
//           <IconButton color="inherit">
//             <AccountCircle />
//           </IconButton>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };
//
// export default Header;




// import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box, Badge, useMediaQuery } from '@mui/material';
// import { styled, alpha, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MenuIcon from '@mui/icons-material/Menu';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//
//   return (
//     <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
//       <Toolbar>
//         <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
//           <MenuIcon />
//         </IconButton>
//         <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
//           DomainTug
//         </Typography>
//         {!isMobile && (
//           <Search>
//             <SearchIconWrapper>
//               <SearchIcon />
//             </SearchIconWrapper>
//             <StyledInputBase
//               placeholder="Search…"
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </Search>
//         )}
//         <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//           <IconButton color="inherit">
//             <Badge badgeContent={4} color="secondary">
//               <NotificationsIcon />
//             </Badge>
//           </IconButton>
//           <IconButton color="inherit">
//             <AccountCircle />
//           </IconButton>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton, InputBase, Box } from '@mui/material';
// import { styled, alpha } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MenuIcon from '@mui/icons-material/Menu';
//
// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
//
// const Header = () => (
//   <AppBar position="static" sx={{ bgcolor: 'primary.main', boxShadow: 3 }}>
//     <Toolbar>
//       <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
//         <MenuIcon />
//       </IconButton>
//       <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
//         DomainTug
//       </Typography>
//       <Search>
//         <SearchIconWrapper>
//           <SearchIcon />
//         </SearchIconWrapper>
//         <StyledInputBase
//           placeholder="Search…"
//           inputProps={{ 'aria-label': 'search' }}
//         />
//       </Search>
//       <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//         <IconButton color="inherit">
//           <NotificationsIcon />
//         </IconButton>
//         <IconButton color="inherit">
//           <AccountCircle />
//         </IconButton>
//       </Box>
//     </Toolbar>
//   </AppBar>
// );
//
// export default Header;
