// import React from 'react';
// import { Box, Typography, Link, Grid, Container, TextField, Button } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const FooterContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: '#2E3B4E', // Darker, more professional background color
//   color: '#FFFFFF', // White text for contrast
//   padding: theme.spacing(6),
//   marginTop: theme.spacing(8),
//   boxShadow: theme.shadows[3],
// }));
//
// const FooterLink = styled(Link)(({ theme }) => ({
//   color: '#A0AEC0', // Light gray color for links
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));
//
// const ContactForm = styled('form')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   gap: theme.spacing(2),
// }));
//
// const Footer = () => (
//   <FooterContainer component="footer">
//     <Container maxWidth="lg">
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             DomainHarbor
//           </Typography>
//           <Typography variant="body2">
//             Your trusted partner in finding the perfect domain name.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Quick Links
//           </Typography>
//           <FooterLink href="/">Home</FooterLink><br />
//           <FooterLink href="/domains">Domains</FooterLink><br />
//           <FooterLink href="/about">About Us</FooterLink><br />
//           <FooterLink href="/help">Help</FooterLink><br />
//           <FooterLink href="/login">Login</FooterLink>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Follow Us
//           </Typography>
//           <FooterLink href="https://twitter.com" target="_blank">Twitter</FooterLink><br />
//           <FooterLink href="https://facebook.com" target="_blank">Facebook</FooterLink><br />
//           <FooterLink href="https://linkedin.com" target="_blank">LinkedIn</FooterLink>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Contact Us
//           </Typography>
//           <ContactForm>
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Name"
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#A0AEC0' }, // Light gray color for input labels
//               }}
//               InputProps={{
//                 style: { color: '#FFFFFF' }, // White color for input text
//               }}
//             />
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Email"
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#A0AEC0' }, // Light gray color for input labels
//               }}
//               InputProps={{
//                 style: { color: '#FFFFFF' }, // White color for input text
//               }}
//             />
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Message"
//               multiline
//               rows={4}
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#A0AEC0' }, // Light gray color for input labels
//               }}
//               InputProps={{
//                 style: { color: '#FFFFFF' }, // White color for input text
//               }}
//             />
//             <Button variant="contained" color="secondary" type="submit">
//               Send
//             </Button>
//           </ContactForm>
//         </Grid>
//       </Grid>
//       <Box textAlign="center" mt={4}>
//         <Typography variant="body2">
//           © {new Date().getFullYear()} DomainHarbor. All rights reserved.
//         </Typography>
//       </Box>
//     </Container>
//   </FooterContainer>
// );
//
// export default Footer;




// import React from 'react';
// import { Box, Typography, Link, Grid, Container, TextField, Button } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const FooterContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.dark,
//   color: theme.palette.common.white,
//   padding: theme.spacing(4),
//   marginTop: theme.spacing(8),
//   boxShadow: theme.shadows[3],
// }));
//
// const FooterLink = styled(Link)(({ theme }) => ({
//   color: theme.palette.common.white,
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));
//
// const ContactForm = styled('form')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   gap: theme.spacing(2),
// }));
//
// const Footer = () => (
//   <FooterContainer component="footer">
//     <Container maxWidth="lg">
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             DomainHarbor
//           </Typography>
//           <Typography variant="body2">
//             Your trusted partner in finding the perfect domain name.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Quick Links
//           </Typography>
//           <FooterLink href="/">Home</FooterLink><br />
//           <FooterLink href="/domains">Domains</FooterLink><br />
//           <FooterLink href="/about">About Us</FooterLink><br />
//           <FooterLink href="/help">Help</FooterLink><br />
//           <FooterLink href="/login">Login</FooterLink>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Follow Us
//           </Typography>
//           <FooterLink href="https://twitter.com" target="_blank">Twitter</FooterLink><br />
//           <FooterLink href="https://facebook.com" target="_blank">Facebook</FooterLink><br />
//           <FooterLink href="https://linkedin.com" target="_blank">LinkedIn</FooterLink>
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <Typography variant="h6" gutterBottom>
//             Contact Us
//           </Typography>
//           <ContactForm>
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Name"
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#fff' },
//               }}
//               InputProps={{
//                 style: { color: '#fff' },
//               }}
//             />
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Email"
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#fff' },
//               }}
//               InputProps={{
//                 style: { color: '#fff' },
//               }}
//             />
//             <TextField
//               variant="outlined"
//               size="small"
//               label="Message"
//               multiline
//               rows={4}
//               fullWidth
//               InputLabelProps={{
//                 style: { color: '#fff' },
//               }}
//               InputProps={{
//                 style: { color: '#fff' },
//               }}
//             />
//             <Button variant="contained" color="secondary" type="submit">
//               Send
//             </Button>
//           </ContactForm>
//         </Grid>
//       </Grid>
//       <Box textAlign="center" mt={4}>
//         <Typography variant="body2">
//           © {new Date().getFullYear()} DomainHarbor. All rights reserved.
//         </Typography>
//       </Box>
//     </Container>
//   </FooterContainer>
// );
//
// export default Footer;




import React from 'react';
import { Box, Typography, Link, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
  boxShadow: theme.shadows[3],
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Footer = () => (
  <FooterContainer component="footer">
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            DomainTug
          </Typography>
          <Typography variant="body2">
            Your trusted partner in finding the perfect domain name.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <FooterLink href="/">Home</FooterLink><br />
          <FooterLink href="/domains">Domains</FooterLink><br />
          <FooterLink href="/about">About Us</FooterLink><br />
          {/*<FooterLink href="/contact">Contact</FooterLink><br />*/}
          <FooterLink href="/help">Help</FooterLink><br />
          {/*<FooterLink href="/login">Login</FooterLink>*/}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          <FooterLink href="https://twitter.com" target="_blank">Twitter</FooterLink><br />
          <FooterLink href="https://facebook.com" target="_blank">Facebook</FooterLink><br />
          <FooterLink href="https://linkedin.com" target="_blank">LinkedIn</FooterLink>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={4}>
        <Typography variant="body2">
          © {new Date().getFullYear()} DomainTug. All rights reserved.
        </Typography>
      </Box>
    </Container>
  </FooterContainer>
);

export default Footer;



// import React from 'react';
// import { Box, Typography, Link, Grid, Container } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const FooterContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.dark,
//   color: theme.palette.common.white,
//   padding: theme.spacing(4),
//   marginTop: theme.spacing(8),
//   boxShadow: theme.shadows[3],
// }));
//
// const FooterLink = styled(Link)(({ theme }) => ({
//   color: theme.palette.common.white,
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));
//
// const Footer = () => (
//   <FooterContainer component="footer">
//     <Container maxWidth="lg">
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={4}>
//           <Typography variant="h6" gutterBottom>
//             DomainHarbor
//           </Typography>
//           <Typography variant="body2">
//             Your trusted partner in finding the perfect domain name.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant="h6" gutterBottom>
//             Quick Links
//           </Typography>
//           <FooterLink href="/">Home</FooterLink><br />
//           <FooterLink href="/domains">Domains</FooterLink><br />
//           <FooterLink href="/about">About Us</FooterLink><br />
//           <FooterLink href="/contact">Contact</FooterLink>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant="h6" gutterBottom>
//             Follow Us
//           </Typography>
//           <FooterLink href="https://twitter.com" target="_blank">Twitter</FooterLink><br />
//           <FooterLink href="https://facebook.com" target="_blank">Facebook</FooterLink><br />
//           <FooterLink href="https://linkedin.com" target="_blank">LinkedIn</FooterLink>
//         </Grid>
//       </Grid>
//       <Box textAlign="center" mt={4}>
//         <Typography variant="body2">
//           © {new Date().getFullYear()} DomainHarbor. All rights reserved.
//         </Typography>
//       </Box>
//     </Container>
//   </FooterContainer>
// );
//
// export default Footer;




// import React from 'react';
// import { Box, Typography, Link, Grid } from '@mui/material';
// import { styled } from '@mui/material/styles';
//
// const FooterContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.dark,
//   color: theme.palette.common.white,
//   padding: theme.spacing(4),
//   marginTop: theme.spacing(8),
//   boxShadow: theme.shadows[3],
// }));
//
// const FooterLink = styled(Link)(({ theme }) => ({
//   color: theme.palette.common.white,
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));
//
// const Footer = () => (
//   <FooterContainer component="footer">
//     <Grid container spacing={4}>
//       <Grid item xs={12} sm={4}>
//         <Typography variant="h6" gutterBottom>
//           DomainHarbor
//         </Typography>
//         <Typography variant="body2">
//           Your trusted partner in finding the perfect domain name.
//         </Typography>
//       </Grid>
//       <Grid item xs={12} sm={4}>
//         <Typography variant="h6" gutterBottom>
//           Quick Links
//         </Typography>
//         <FooterLink href="/">Home</FooterLink><br />
//         <FooterLink href="/domains">Domains</FooterLink><br />
//         <FooterLink href="/about">About Us</FooterLink><br />
//         <FooterLink href="/contact">Contact</FooterLink>
//       </Grid>
//       <Grid item xs={12} sm={4}>
//         <Typography variant="h6" gutterBottom>
//           Follow Us
//         </Typography>
//         <FooterLink href="https://twitter.com" target="_blank">Twitter</FooterLink><br />
//         <FooterLink href="https://facebook.com" target="_blank">Facebook</FooterLink><br />
//         <FooterLink href="https://linkedin.com" target="_blank">LinkedIn</FooterLink>
//       </Grid>
//     </Grid>
//     <Box textAlign="center" mt={4}>
//       <Typography variant="body2">
//         © {new Date().getFullYear()} DomainHarbor. All rights reserved.
//       </Typography>
//     </Box>
//   </FooterContainer>
// );
//
// export default Footer;
