import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { logEvent } from '../analytics';

const ResultsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '800px',
  margin: 'auto',
  marginTop: theme.spacing(4),
}));

const AnimatedIconButton = styled(IconButton)(({ theme, color }) => ({
  '&:hover svg': {
    animation: 'rainbow 1s infinite',
  },
  '@keyframes rainbow': {
    '0%': { fill: 'red' },
    '14%': { fill: 'orange' },
    '28%': { fill: 'yellow' },
    '42%': { fill: 'green' },
    '57%': { fill: 'blue' },
    '71%': { fill: 'indigo' },
    '85%': { fill: 'violet' },
    '100%': { fill: 'red' },
  },
  color: color,
}));

const DomainResults = ({ results }) => {
  const handlePurchaseClick = (domain, provider) => {
    logEvent('DomainResults', 'purchase', `${provider}: ${domain}`);
  };

  return (
    <ResultsContainer>
      {/*<Typography variant="h5" gutterBotto align={"center"} bgcolor={"blue"}>*/}
      <Typography variant="h5" gutterBottom align="center" sx={{ bgcolor: '#3a3a3a', color: 'white', padding: 1, borderRadius: 1 }}>
        Available Domains
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Domain Name
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="center">
          <Typography variant="subtitle1" color="textSecondary">
            Namecheap
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="center">
          <Typography variant="subtitle1" color="textSecondary">
            GoDaddy
          </Typography>
        </Grid>
      </Grid>
      <List>
        {results.map((result, index) => (
          <ListItem key={index} divider>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <ListItemText primary={result.name} />
              </Grid>
              <Grid item xs={3} textAlign="center">
                <AnimatedIconButton
                  edge="end"
                  aria-label="buy"
                  href={`https://www.namecheap.com/domains/registration/results/?domain=${result.name}`}
                  target="_blank"
                  onClick={() => handlePurchaseClick(result.name, 'Namecheap')}
                  color="primary"
                >
                  <ShoppingCartIcon />
                </AnimatedIconButton>
                <Typography variant="caption" display="block">
                  Buy
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <AnimatedIconButton
                  edge="end"
                  aria-label="buy"
                  href={`https://www.godaddy.com/en-uk/domainsearch/find?domainToCheck=${result.name}`}
                  target="_blank"
                  onClick={() => handlePurchaseClick(result.name, 'GoDaddy')}
                  color="secondary"
                >
                  <ShoppingCartIcon />
                </AnimatedIconButton>
                <Typography variant="caption" display="block">
                  Buy
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </ResultsContainer>
  );
};

export default DomainResults;








// import React from 'react';
// import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { logEvent } from '../analytics';
//
// const ResultsContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
//   '&:hover svg': {
//     animation: 'rainbow 1s infinite',
//   },
//   '@keyframes rainbow': {
//     '0%': { fill: 'red' },
//     '14%': { fill: 'orange' },
//     '28%': { fill: 'yellow' },
//     '42%': { fill: 'green' },
//     '57%': { fill: 'blue' },
//     '71%': { fill: 'indigo' },
//     '85%': { fill: 'violet' },
//     '100%': { fill: 'red' },
//   },
// }));
//
// const DomainResults = ({ results }) => {
//   const handlePurchaseClick = (domain) => {
//     logEvent('DomainResults', 'purchase', domain);
//   };
//
//   return (
//     <ResultsContainer>
//       <Typography variant="h5" gutterBottom>
//         Available Domains
//       </Typography>
//       <List>
//         {results.map((result, index) => (
//           <ListItem key={index} divider>
//             <ListItemText
//               primary={result.name}
//               // secondary={`Price: $${result.price}`}
//             />
//             <ListItemSecondaryAction>
//               <AnimatedIconButton
//                 edge="end"
//                 aria-label="buy"
//                 href={`https://www.namecheap.com/domains/registration/results/?domain=${result.name}`}
//                 target="_blank"
//                 onClick={() => handlePurchaseClick(result.name)}
//               >
//                 <ShoppingCartIcon />
//               </AnimatedIconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         ))}
//       </List>
//     </ResultsContainer>
//   );
// };
//
// export default DomainResults;




// import React from 'react';
// import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { logEvent } from '../analytics';
//
// const ResultsContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const DomainResults = ({ results }) => {
//   const handlePurchaseClick = (domain) => {
//     logEvent('DomainResults', 'purchase', domain);
//   };
//
//   return (
//     <ResultsContainer>
//       <Typography variant="h5" gutterBottom>
//         Available Domains
//       </Typography>
//       <List>
//         {results.map((result, index) => (
//           <ListItem key={index} divider>
//             <ListItemText
//               primary={result.name}
//               // secondary={`Price: $${result.price}`}
//             />
//             <ListItemSecondaryAction>
//               <IconButton
//                 edge="end"
//                 aria-label="buy"
//                 href={result.purchase_url}
//                 target="_blank"
//                 onClick={() => handlePurchaseClick(result.name)}
//               >
//                 <ShoppingCartIcon />
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         ))}
//       </List>
//     </ResultsContainer>
//   );
// };
//
// export default DomainResults;





// import React from 'react';
// import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { logEvent } from '../analytics';
//
// const ResultsContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const DomainResults = ({ results }) => {
//   const handlePurchaseClick = (domain) => {
//     logEvent('DomainResults', 'purchase', domain);
//   };
//
//   return (
//     <ResultsContainer>
//       <Typography variant="h5" gutterBottom>
//         Available Domains
//       </Typography>
//       <List>
//         {results.map((result, index) => (
//           <ListItem key={index} divider>
//             <ListItemText
//               primary={result.domain}
//               secondary={`Price: $${result.price}`}
//             />
//             <ListItemSecondaryAction>
//               <IconButton edge="end" aria-label="buy" href={result.purchaseLink} target="_blank" onClick={() => handlePurchaseClick(result.domain)}>
//                 <ShoppingCartIcon />
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         ))}
//       </List>
//     </ResultsContainer>
//   );
// };
//
// export default DomainResults;



// import React from 'react';
// import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
//
// const ResultsContainer = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   maxWidth: '800px',
//   margin: 'auto',
//   marginTop: theme.spacing(4),
// }));
//
// const DomainResults = ({ results }) => (
//   <ResultsContainer>
//     <Typography variant="h5" gutterBottom>
//       Available Domains
//     </Typography>
//     <List>
//       {results.map((result, index) => (
//         <ListItem key={index} divider>
//           <ListItemText
//             primary={result.domain}
//             secondary={`Price: $${result.price}`}
//           />
//           <ListItemSecondaryAction>
//             <IconButton edge="end" aria-label="buy" href={result.purchaseLink} target="_blank">
//               <ShoppingCartIcon />
//             </IconButton>
//           </ListItemSecondaryAction>
//         </ListItem>
//       ))}
//     </List>
//   </ResultsContainer>
// );
//
// export default DomainResults;
