import {
  DOMAIN_SEARCH_REQUEST,
  DOMAIN_SEARCH_SUCCESS,
  DOMAIN_SEARCH_FAILURE,
  SET_DOMAIN_RESULTS,
} from '../actions/domainActions';

const initialState = {
  results: [],
  loading: false,
  error: null,
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOMAIN_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DOMAIN_SEARCH_SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
        error: null,
      };
    case DOMAIN_SEARCH_FAILURE:
      return {
        ...state,
        results: [],
        loading: false,
        error: action.payload,
      };
    case SET_DOMAIN_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    default:
      return state;
  }
};

export default domainReducer;
