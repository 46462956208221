import axios from 'axios';

// Create an Axios instance with default settings
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://domaintug-backend-917245734424.us-central1.run.app', // Default to localhost if not set
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000, // 10 seconds timeout
});

// Interceptors for request and response
api.interceptors.request.use(
  (config) => {
    // Add authorization token if available
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    if (error.response && error.response.status === 401) {
      // Unauthorized access - redirect to login or handle token refresh
      console.error('Unauthorized access - redirecting to login');
    }
    return Promise.reject(error);
  }
);

// API service functions

// Search for domains
export const searchDomains = async (params) => {
  const response = await api.post('/api/v1/domains/', params);
  return response.data;
};

// Login user
export const loginUser = async (credentials) => {
  const response = await api.post('/api/auth/login', credentials);
  return response.data;
};

// Register user
export const registerUser = async (userData) => {
  const response = await api.post('/api/auth/register', userData);
  return response.data;
};

// Logout user
export const logoutUser = async () => {
  const response = await api.post('/api/auth/logout');
  return response.data;
};

// Fetch user profile
export const fetchUserProfile = async () => {
  const response = await api.get('/api/auth/profile');
  return response.data;
};

// Update user profile
export const updateUserProfile = async (profileData) => {
  const response = await api.put('/api/auth/profile', profileData);
  return response.data;
};




// import axios from 'axios';
//
// // Create an Axios instance with default settings
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   timeout: 10000, // 10 seconds timeout
// });
//
// // Interceptors for request and response
// api.interceptors.request.use(
//   (config) => {
//     // Add authorization token if available
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );
//
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle errors globally
//     if (error.response && error.response.status === 401) {
//       // Unauthorized access - redirect to login or handle token refresh
//       console.error('Unauthorized access - redirecting to login');
//     }
//     return Promise.reject(error);
//   }
// );
//
// // API service functions
//
// // Search for domains
// export const searchDomains = async (params) => {
//   const response = await api.post('/domains', params);
//   return response.data;
// };
//
// // Login user
// export const loginUser = async (credentials) => {
//   const response = await api.post('/auth/login', credentials);
//   return response.data;
// };
//
// // Register user
// export const registerUser = async (userData) => {
//   const response = await api.post('/auth/register', userData);
//   return response.data;
// };
//
// // Logout user
// export const logoutUser = async () => {
//   const response = await api.post('/auth/logout');
//   return response.data;
// };
//
// // Fetch user profile
// export const fetchUserProfile = async () => {
//   const response = await api.get('/auth/profile');
//   return response.data;
// };
//
// // Update user profile
// export const updateUserProfile = async (profileData) => {
//   const response = await api.put('/auth/profile', profileData);
//   return response.data;
// };
