import { loginUser, registerUser, logoutUser } from '../../services/apiService';

// Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGOUT = 'LOGOUT';

// Action Creators

// Login Actions
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Register Actions
export const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

// Logout Action
export const logoutAction = () => ({
  type: LOGOUT,
});

// Thunk Actions

// Login Thunk
export const login = (credentials) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const user = await loginUser(credentials);
    dispatch(loginSuccess(user));
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

// Register Thunk
export const register = (userData) => async (dispatch) => {
  dispatch(registerRequest());
  try {
    const user = await registerUser(userData);
    dispatch(registerSuccess(user));
  } catch (error) {
    dispatch(registerFailure(error.message));
  }
};

// Logout Thunk
export const logout = () => async (dispatch) => {
  await logoutUser();
  dispatch(logoutAction());
};




// import { loginUser, registerUser, logoutUser } from '../../services/apiService';
//
// // Action Types
// export const LOGIN_REQUEST = 'LOGIN_REQUEST';
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_FAILURE = 'LOGIN_FAILURE';
//
// export const REGISTER_REQUEST = 'REGISTER_REQUEST';
// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const REGISTER_FAILURE = 'REGISTER_FAILURE';
//
// export const LOGOUT = 'LOGOUT';
//
// // Action Creators
//
// // Login Actions
// export const loginRequest = () => ({
//   type: LOGIN_REQUEST,
// });
//
// export const loginSuccess = (user) => ({
//   type: LOGIN_SUCCESS,
//   payload: user,
// });
//
// export const loginFailure = (error) => ({
//   type: LOGIN_FAILURE,
//   payload: error,
// });
//
// // Register Actions
// export const registerRequest = () => ({
//   type: REGISTER_REQUEST,
// });
//
// export const registerSuccess = (user) => ({
//   type: REGISTER_SUCCESS,
//   payload: user,
// });
//
// export const registerFailure = (error) => ({
//   type: REGISTER_FAILURE,
//   payload: error,
// });
//
// // Logout Action
// export const logout = () => ({
//   type: LOGOUT,
// });
//
// // Thunk Actions
//
// // Login Thunk
// export const login = (credentials) => async (dispatch) => {
//   dispatch(loginRequest());
//   try {
//     const user = await loginUser(credentials);
//     dispatch(loginSuccess(user));
//   } catch (error) {
//     dispatch(loginFailure(error.message));
//   }
// };
//
// // Register Thunk
// export const register = (userData) => async (dispatch) => {
//   dispatch(registerRequest());
//   try {
//     const user = await registerUser(userData);
//     dispatch(registerSuccess(user));
//   } catch (error) {
//     dispatch(registerFailure(error.message));
//   }
// };
//
// // Logout Thunk
// export const logout = () => async (dispatch) => {
//   await logoutUser();
//   dispatch(logout());
// };
